import React, { memo, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StoreState } from 'store';

import StartLayout from 'components/StartLayout/StartLayout';
import Reference from 'components/Reference';
import LoginForm from './LoginForm';
import ClientType from './ClientType';
import useLogin from './useLogin';
import RouteIndex from 'RouteIndex';

import './login.scss';

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const amount = new URLSearchParams(location.search).get('amount');
  const orderId = new URLSearchParams(location.search).get('orderId');
  const packageP = new URLSearchParams(location.search).get('package');
  const state = location.state as { from?: string };
  const loggedIn = useSelector<StoreState, boolean>(state => state.session.loggedIn);
  const [{ email, password, loading, errors, touched }, { handleChange, handleLogin, handleBlur }] = useLogin(
    state?.from
  );

  const [step, setStep] = useState<'form' | 'type'>('form');

  const handleSelect = () => {
    setStep('form');
  };

  useEffect(() => {
    if (loggedIn) {
      const widgetScriptUrl = '//chatbot.lexsecure.com/apps/widget/bundle.js';
      const slug = /\/?([\w-]+)\.js/.exec('/lex.js')![1];
      const token = localStorage.getItem('accessToken');
      const ref = slug + '$token=' + token;
      const script = document.createElement('script');

      document.getElementsByTagName('head')[0].appendChild(script);
      script.setAttribute('type', 'text/javascript');
      script.addEventListener('load', () => {
        // @ts-expect-error: Cannot find name 'Widget'
        Widget.init({
          slug: ref,
        });
      });
      script.setAttribute('src', widgetScriptUrl);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [loggedIn]);

  if (loggedIn) {
    return <Navigate to={RouteIndex.profile} />;
  }

  return (
    <div className="login">
      <StartLayout
        top={
          <Reference
            text={t('login:reference_text')}
            link={t('login:register')}
            href={`${RouteIndex.register}${amount ? `?amount=${amount}&orderId=${orderId}&package=${packageP}` : ''}`}
          />
        }
      >
        {step === 'type' && <ClientType onSelect={handleSelect} />}
        {step === 'form' && (
          <LoginForm
            touched={touched}
            email={email}
            password={password}
            errors={errors}
            loading={loading}
            onChange={handleChange}
            onClickLogin={handleLogin}
            onBlur={handleBlur}
          />
        )}
      </StartLayout>
    </div>
  );
};

export default memo(Login);
