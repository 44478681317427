import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import Search from 'components/Search';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import Flex from 'components/Flex';
import Button from 'components/Button';

import { ReactComponent as DocumentIcon } from 'assets/images/icon_document.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icon-plus.svg';

type Props = {
  handleClick: () => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSelect: (selectedValue: string) => void;
  searchValue: string;
  date: string;
  status: string;
};

const FilterBar: FC<Props> = ({ handleChange, handleClick, handleSelect, searchValue, date, status }) => {
  const { t } = useTranslation('applications');

  return (
    <Flex className="applications__filter-bar" justify="space-between">
      <Flex className="applications__filters">
        <Search onChange={handleChange} value={searchValue} />
        <DatePicker value={date} onChenge={handleChange} />
        <Select
          value={status || 'placeholder'}
          options={['', 'active', 'closed', 'resign']}
          onSelect={handleSelect}
          startIcon={<DocumentIcon />}
        />
      </Flex>

      <Button onClick={handleClick} width="auto" height="compact" startIcon={<PlusIcon />}>
        <span>{t('new_application')}</span>
      </Button>
    </Flex>
  );
};

export default FilterBar;
