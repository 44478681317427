import React, { FC, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ClickAway from 'components/ClickAway';
import SelectController from './items/SelectController';
import SelectOptions from './items/SelectOptions';

import './record-amount-select.scss';

type RecordAmountSelectProps = {
  value: number;
  onSelect: (option: number) => void;
  options: number[];
};

const Select: FC<RecordAmountSelectProps> = ({ onSelect, value, options = [] }) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selectedOption, setOption] = useState<number>(value);

  const handleMenuOpen = () => {
    setVisible(true);
  };

  const handleMenuClose = () => {
    setVisible(false);
  };

  const handleSelect = (option: number) => {
    onSelect(option);
    setOption(option);
    handleMenuClose();
  };

  return (
    <div className="record-amount-select">
      <SelectController
        isVisible={isVisible}
        onClick={handleMenuOpen}
        onSelect={handleSelect}
        selectedOption={selectedOption}
      />

      <ClickAway onClickAway={handleMenuClose}>
        <CSSTransition in={isVisible} timeout={200} classNames="options-fade-animation" unmountOnExit>
          <SelectOptions options={options} onSelect={handleSelect} isVisible={isVisible} />
        </CSSTransition>
      </ClickAway>
    </div>
  );
};

export default Select;
