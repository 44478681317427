import React, { FC, memo, ReactNode } from 'react';
import clsx from 'clsx';
import Pagination from 'components/Pagination';
import Flex from 'components/Flex';
import './table-layout.scss';

const TableLayout: FC<Props> = ({
  children,
  afterTable,
  beforeTable,
  className = '',
  currentPage = 1,
  limit = 1,
  itemCount = 1,
  pageCount = 1,
  onChangeLimit,
  onChangePage,
  onClickLast,
  onClickNext,
  onClickPrevious,
  onClickFirst,
}) => {
  const classes = clsx('table-layout', className);
  const containerClasses = clsx('table-layout__table-container', {
    'with-after': afterTable,
    'with-before': beforeTable,
  });

  return (
    <Flex className={classes} justify="space-between" direction="column" width="fill">
      <Flex direction="column" width="fill" className="table-layout__table-wrapper" justify="space-between">
        {beforeTable}
        <div className={containerClasses}>
          <div className="table-layout__table">{children}</div>
        </div>
        {afterTable}
      </Flex>

      <Flex width="fill" className="table-layout__pagination">
        <Pagination
          itemCount={itemCount}
          currentPage={currentPage}
          limit={limit}
          pageCount={pageCount}
          onChangeLimit={onChangeLimit}
          onChangePage={onChangePage}
          onClickLast={onClickLast}
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
          onClickFirst={onClickFirst}
        />
      </Flex>
    </Flex>
  );
};

type Props = {
  className?: string;
  children?: ReactNode;
  beforeTable?: ReactNode;
  afterTable?: ReactNode;
  currentPage: number;
  itemCount?: number;
  limit?: number;
  pageCount?: number;
  onClickFirst: () => void;
  onClickPrevious: () => void;
  onClickNext: () => void;
  onClickLast: () => void;
  onChangeLimit: (limit: number) => void;
  onChangePage: (event: any) => void;
};

export default memo(TableLayout);
