class Route {
  public readonly path: string;

  constructor(path: string) {
    this.path = path;
  }

  get = (params: Record<string, string>): string => {
    let result = this.path;

    Object.keys(params).forEach(key => {
      const re = new RegExp(`:${key}`, 'g');
      result = result.replace(re, params[key]);
    });

    return result;
  };

  static create = (path: string): Route => {
    return new Route(path);
  };
}

export default {
  home: '/',
  login: '/login',
  register: '/register',
  passwordRecovery: '/recover-password',
  applicationCreation: '/application/new',
  applicationDetails: Route.create('/application/:id'),
  profile: '/profile',
  activate: '/activate',
  resetPassword: '/password/reset',
  registerSuccess: '/register-success',
  activateError: '/activate-error',
  welcome: '/welcome',
  payment: '/payment',
};
