import React, { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode[];
  onClick?: () => void;
};

const TableRow: FC<Props> = ({ children, onClick }) => (
  <tr className="table__row" onClick={onClick}>
    {children}
  </tr>
);

export default TableRow;
