import Headline from 'components/Headline';
import React, { memo, useEffect } from 'react';
import Reference from 'components/Reference';
import StartLayout from 'components/StartLayout';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteIndex from 'RouteIndex';
import Logo from 'assets/images/logo.png';

import './welcome.scss';

const Welcome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirect = !(new URLSearchParams(search).get('redirect') === 'false');
  const amount = new URLSearchParams(search).get('amount');
  const orderId = new URLSearchParams(search).get('orderId');
  const packageP = new URLSearchParams(search).get('package');
  let timeout: any;

  useEffect(() => {
    if (redirect) {
      timeout = setTimeout(() => {
        navigate(`/login?amount=${amount}&orderId=${orderId}&package=${packageP}`);
      }, 5000);
    }
  }, []);
  return (
    <div className="Welcome">
      <StartLayout
        top={
          <Reference
            onClickReference={() => (timeout ? clearTimeout(timeout) : null)}
            text={t('welcome:reference_text')}
            link={t('welcome:register')}
            href={`${RouteIndex.register}${amount ? `?amount=${amount}&orderId=${orderId}&package=${packageP}` : ''}`}
          />
        }
      >
        <img src={Logo} alt="" className="welcome-topbar__logo" />
        <Headline className="welcome__headling--center">{t('welcome:text')}</Headline>
        <span className="text2">{t('welcome:text2')}</span>
      </StartLayout>
    </div>
  );
};

export default memo(Welcome);
