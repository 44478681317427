import { PayloadAction } from '@reduxjs/toolkit';

import {
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  FETCH_CASE_DETAILS,
  FETCH_CASE_LIST,
  FETCH_PROFILE,
  REMOVE_SNACKBAR,
  SET_NAVBAR_ACTIONS,
  SET_VIEW_TITLE,
} from 'actions';
import { NavbarActionsTypes } from 'components/Navbar/NavActions/NavActions';
import { SnackbarKey } from 'notistack';

export type Profile = {
  PESEL: string | null;
  activePackage: { name: string, activeTo: string };
  additionalNr: string;
  companyName: string | null;
  email: string;
  id: number;
  name: string;
  phone: string | null;
  surname: string;
};

type NotificationType = 'success' | 'error' | 'warning' | 'info';

export class Notification {
  message: string;
  type: NotificationType;
  key: SnackbarKey;
  options?: Record<string, any>;
  dismissed: boolean;

  constructor(message: string, type: NotificationType) {
    this.message = message;
    this.type = type;
    this.dismissed = false;
    this.key = new Date().getTime() + Math.random();
  }
}

export type DefaultState = {
  case: any;
  profile?: Profile;
  caseListData: any;
  notifications: Notification[];
  navbarActions: NavbarActionsTypes;
  viewTitle: string;
};

const defaultState: DefaultState = {
  case: {},
  caseListData: {},
  notifications: [],
  profile: undefined,
  navbarActions: {},
  viewTitle: '',
};

const interfaceReducer = (state = defaultState, action: PayloadAction<DefaultState>) => {
  switch (action.type) {
    case FETCH_PROFILE:
      return { ...state, profile: action.payload };

    case FETCH_CASE_DETAILS:
      return { ...state, case: { ...action.payload } };

    case FETCH_CASE_LIST:
      return { ...state, caseListData: action.payload };

    case SET_NAVBAR_ACTIONS:
      const { navbarActions } = action.payload;
      return { ...state, navbarActions };

    case SET_VIEW_TITLE:
      const { viewTitle } = action.payload;
      return { ...state, viewTitle };

    case ENQUEUE_SNACKBAR:
      const notification = (action as any)?.notification as Notification;

      return {
        ...state,
        notifications: [...state.notifications, notification],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
      };

    case REMOVE_SNACKBAR:
      const key = (action as any)?.key;

      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== key),
      };

    default:
      return { ...state };
  }
};

export default interfaceReducer;
