import createActivityDetector from 'activity-detector';
import Layout from 'components/Layout';
import ProtectedRoute from 'components/ProtectedRoute';
import useSession from 'hooks/useSession';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RouteIndex from 'RouteIndex';
import { StoreState } from 'store';
import {
  getFromLocalStorage,
  refreshTokenKey,
  removeFromLocalStorage,
  saveToLocalStorage,
  userActivityTimeKey,
} from 'utils/localStorage';
import Activate from './Activate';
import ActivateError from './ActivateError';
import ApplicationDetails from './ApplicationDetails';
import Applications from './Applications';
import Login from './Login';
import NewApplication from './NewApplication';
import PasswordRecovery from './PasswordRecovery';
import Profile from './Profile';
import Register from './Register';
import RegisterSuccess from './RegisterSuccess';
import ResetPassword from './ResetPassword';
import Welcome from './Welcome/Welcome';
import jwt_decode from 'jwt-decode';
import { enqueueSnackbar } from 'actions';
import { Notification } from 'reducers';
import { useTranslation } from 'react-i18next';
import Payment from './payment/payment';

type ParsedToken = {
  id: string;
  name: string;
  roles: string[];
  status: string | null;
  iat: number;
  exp: number;
};

export const Router: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loggedIn = useSelector<StoreState, boolean>(state => state.session.loggedIn);
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const { logout } = useSession();

  const idleTime = 30 * 60 * 1000; //30 min in ms
  const activityDetector = createActivityDetector({
    timeToIdle: 1000,
    autoInit: false,
  });

  activityDetector.on('active', () => {
    const lastTimeActive = getFromLocalStorage(userActivityTimeKey) ?? Date.now();
    if (lastTimeActive !== null && +lastTimeActive + idleTime <= Date.now()) {
      removeFromLocalStorage(userActivityTimeKey);
      removeFromLocalStorage(refreshTokenKey);
      logout();
      return dispatch(enqueueSnackbar(new Notification(t('login:logout'), 'info')));
    } else {
      saveToLocalStorage(userActivityTimeKey, Date.now().toString());
    }
  });

  activityDetector.on('idle', () => {
    // do nothing
  });

  useEffect(() => {
    if (!loggedIn) {
      activityDetector.stop();
      const refreshToken = getFromLocalStorage(refreshTokenKey);
      if (refreshToken) {
        const decoded = jwt_decode<ParsedToken>(refreshToken);
        const expired = new Date().getTime() - decoded.exp * 1000 > 0;
        !expired && logout();
      }
    } else {
      saveToLocalStorage(userActivityTimeKey, Date.now().toString());
      activityDetector.init();
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    const id = setInterval(() => {
      const parsedToken = accessToken ? jwt_decode<ParsedToken>(accessToken) : null;
      if (loggedIn && parsedToken) {
        const accesTokenExpired = new Date().getTime() - (parsedToken.exp * 1000 - 50000) > 0;

        if (accesTokenExpired) {
          logout();
        }

        return;
      }

      logout();
      removeFromLocalStorage(userActivityTimeKey);
    }, 100000);
    return () => clearInterval(id);
  }, [loggedIn, dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={RouteIndex.welcome} element={<Welcome />} />
        <Route path={RouteIndex.login} element={<Login />} />
        <Route path={RouteIndex.register} element={<Register />} />
        <Route path={RouteIndex.passwordRecovery} element={<PasswordRecovery />} />
        <Route path={RouteIndex.activate} element={<Activate />} />
        <Route path={RouteIndex.resetPassword} element={<ResetPassword />} />
        <Route path={RouteIndex.registerSuccess} element={<RegisterSuccess />} />
        <Route path={RouteIndex.activateError} element={<ActivateError />} />
        <Route path={RouteIndex.payment} element={<Payment />} />
        <Route path="/" element={<ProtectedRoute element={<Layout />} fallback={RouteIndex.login} />}>
          <Route index element={<Applications />} />
          <Route path={RouteIndex.applicationCreation} element={<NewApplication />} />
          <Route path={RouteIndex.applicationDetails.path} element={<ApplicationDetails />} />
          <Route path={RouteIndex.profile} element={<Profile />} />
          <Route path="*" element={<div>404</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
