import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Flex from 'components/Flex';
import Reference from 'components/Reference';
import TypeButton from '../TypeButton';

import PersonIcon from 'assets/images/icon-person.svg';
import OrgIcon from 'assets/images/icon-org.svg';
import RouteIndex from 'RouteIndex';
import './client-type.scss';

const ClientType: FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation();

  const handleSelect = (type: string) => () => {
    onSelect(type);
  };

  return (
    <Flex direction="column" className="client-type">
      <h1 className="client-type__heading">{t('login:login')}</h1>
      <p className="client-type__text">{t('login:login_subtitle')}</p>

      <TypeButton onClick={handleSelect('')} icon={PersonIcon}>
        {t('login:client_individual')}
      </TypeButton>

      <TypeButton onClick={handleSelect('')} icon={OrgIcon}>
        {t('login:client_business')}
      </TypeButton>

      <Reference
        text={t('login:reference_text')}
        link={t('login:register')}
        className="client-type__reference"
        href={RouteIndex.register}
      />
    </Flex>
  );
};

type Props = {
  onSelect: (type: string) => void;
};

export default memo(ClientType);
