import React, { FC, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as Chevron } from 'assets/images/chevron.svg';
import './accordion.scss';

type Props = {
  buttonContent: string | ReactNode;
  classes?: {
    root: string;
    button: string;
    list: string;
  };
  children: ReactNode[];
};

const Accordion: FC<Props> = ({ buttonContent, children, classes }) => {
  const [isListVisible, setListVisible] = useState(false);

  const wrapperClass = clsx('accordion', classes?.root);
  const buttonClasses = clsx('sidebar__link', 'accordion-trigger', classes?.button);
  const listClasses = clsx('accordion__list', classes?.list);
  const chevronClasses = clsx('accordion_chevron', { 'accordion_chevron--open': isListVisible });

  const toggleList = () => setListVisible(prevState => !prevState);

  return (
    <div className={wrapperClass}>
      <button className={buttonClasses} onClick={toggleList} type="button">
        {buttonContent}
        <Chevron className={chevronClasses} />
      </button>

      <CSSTransition in={isListVisible} timeout={200} classNames="fade-in-animation" unmountOnExit>
        <ul className={listClasses}>
          {children?.map((item, i) => (
            <li key={i} className="accordion__item">
              {item}
            </li>
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
};

export default Accordion;
