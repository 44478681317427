import { fetchProfile } from 'actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from 'reducers';
import { StoreState } from 'store';

const useProfile = (): UseProfile => {
  const profile = useSelector<StoreState, Profile | undefined>(state => state.interface.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile) {
      return;
    }

    dispatch(fetchProfile());
  }, [profile, dispatch]);

  return { profile: profile || undefined };
};

type UseProfile = {
  profile?: Profile;
};

export default useProfile;
