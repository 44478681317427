import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from 'reducers/session';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

import interfaceReducer, { DefaultState } from './reducers';
import { SessionState } from './reducers/session';

export type StoreState = { interface: DefaultState; session: SessionState };

export const store = configureStore({
  reducer: combineReducers({ interface: interfaceReducer, session: sessionReducer }),
  devTools: false,
  middleware: [thunk],
});
