/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, ReactNode } from 'react';

import TableHead from './items/TableHead';
import TableBody from './items/TableBody';

import './table.scss';

type Props = {
  collumns: string[];
  children: ReactNode | ReactNode[];
};

const Table: FC<Props> = ({ children, collumns }) => {
  return (
    <table className="table">
      <TableHead collumns={collumns} />
      <TableBody>{children}</TableBody>
    </table>
  );
};

export default memo(Table);
