import React, { FC, ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import Flex from 'components/Flex';
import Logo from 'assets/images/logo.png';
import './start-layout.scss';

const WideStartLayout: FC<Props> = ({ children, top, className = '', contentClass = '', contentWrapperClass = '' }) => {
  const rootClasses = clsx('start-layout', className);
  const contentClasses = clsx('content__container_wide', contentClass);
  const contentWrapperClasses = clsx('start-layout__content', contentWrapperClass);

  return (
    <div className={rootClasses}>
      <Flex align="center" className="start-layout__topbar">
        <Flex align="center" justify="space-between" className="topbar__container">
          <img src={Logo} alt="" className="topbar__logo" />
          <div className="topbar__right">{top}</div>
        </Flex>
      </Flex>

      <div className={contentWrapperClasses}>
        <div className={contentClasses}>{children}</div>
      </div>
    </div>
  );
};

type Props = {
  children: ReactNode;
  top?: ReactElement | string;
  className?: string;
  contentClass?: string;
  contentWrapperClass?: string;
};

export default WideStartLayout;
