import React, { FC, memo, ReactElement, useCallback, useState } from 'react';
import clsx from 'clsx';
import Flex from 'components/Flex';
import './input.scss';

const Input: FC<Props> = ({
  value,
  type = 'text',
  width = 'auto',
  placeholder = '',
  className = '',
  name = '',
  error = '',
  endIcon,
  startIcon,
  onChange,
  onBlur,
}) => {
  const [focused, setFocused] = useState(false);
  const classes = clsx('input', `width-${width}`, {
    [`start-icon`]: startIcon,
  });
  const wrapperClasses = clsx('input-wrapper', `width-${width}`, className, {
    error,
    filled: !!value,
    focused,
  });
  const errorClasses = clsx('input-wrapper__error', { visible: error });

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(
    e => {
      setFocused(false);
      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    },
    [onBlur]
  );

  return (
    <Flex className={wrapperClasses} justify="space-between" align="center">
      {startIcon && <div className="input-wrapper__start-icon">{startIcon}</div>}
      <input
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        className={classes}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {endIcon && <div className="input-wrapper__icon">{endIcon}</div>}

      <div className={errorClasses}>
        <span>{error}</span>
      </div>
    </Flex>
  );
};

type Props = {
  placeholder?: string;
  className?: string;
  error?: string;
  endIcon?: ReactElement;
  startIcon?: ReactElement;
  value: string;
  name?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  width?: 'auto' | 'fill' | 'flex';
  type?: React.HTMLInputTypeAttribute;
};

export default memo(Input);
