import axios from 'axios';
import { Methods } from './Endpoint';

export const HOST = process.env.REACT_APP_API_URL;

const instance = axios.create({ baseURL: HOST });
const headers = {
  authorization: '',
};

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    headers.authorization = `Bearer ${token}`;
    config.headers = headers;
  }

  return config;
});

instance.interceptors.response.use(
  response => {
    if (response?.data?.errors) {
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('refreshToken');
      // window.location.replace('/');
    }

    return response;
  },
  error => {
    const code = error?.response?.status;

    if (code === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.replace('/');
    }

    return Promise.reject(error);
  }
);

const client = {
  use: (method: Methods) => {
    switch (method) {
      case 'POST':
        return instance.post;
      case 'GET':
        return instance.get;
      case 'DELETE':
        return instance.delete;
      case 'PUT':
        return instance.put;
      default:
        return instance.get;
    }
  },
};

export default client;
