import React, { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { SelectControllerProps } from '../Select.types';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron.svg';

const SelectController: FC<SelectControllerProps> = ({
  classes,
  isVisible,
  label,
  onClick,
  selectedOption,
  startIcon,
}) => {
  const { t } = useTranslation('common');

  const controllerClasses = clsx('select__controller', classes?.controller);
  const iconClasses = clsx('controller__icon', { 'controller__icon--rotated': isVisible });
  const labelClasses = clsx('controller-wrapper__label', classes?.label);

  return (
    <div className="select__controller-wrapper">
      {label && <span className={labelClasses}>{label}</span>}

      <button type="button" className={controllerClasses} onClick={onClick}>
        <span className="controller__value">
          {startIcon}
          {t(`status_${selectedOption}`)}
        </span>
        <ChevronIcon className={iconClasses} />
      </button>
    </div>
  );
};

export default SelectController;
