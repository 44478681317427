import React, { ChangeEvent, FC } from 'react';

import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar.svg';
import { ReactComponent as Chevron } from 'assets/images/chevron.svg';

import './date-picker.scss';

type Props = {
  onChenge: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

const DatePicker: FC<Props> = ({ onChenge, value }) => {
  return (
    <div className="date-picker">
      <CalendarIcon />
      <input type="date" name="date" value={value} className="date-picker__input" onChange={onChenge} />
      <Chevron className="date-picker__chevron" />
    </div>
  );
};

export default DatePicker;
