import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StartLayout from 'components/StartLayout';
import Headline from 'components/Headline';
import Button from 'components/Button';
import RouteIndex from 'RouteIndex';
import './register-success.scss';

const RegisterSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RouteIndex.login);
  };

  return (
    <div className="register-success">
      <StartLayout contentWrapperClass="register-success__container" contentClass="register-success__content">
        <Headline className="register-success__headline">{t('login:register_success')}</Headline>

        <Button width="fill" onClick={handleClick}>
          {t('login:register_success_button')}
        </Button>
      </StartLayout>
    </div>
  );
};

export default memo(RegisterSuccess);
