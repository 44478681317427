import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import reportWebVitals from './reportWebVitals';
import App from './components/App';
import { store } from 'store';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import './i18n';
import 'assets/styles/index.scss';

ReactGA.initialize('G-RH4JRZ27JX');

const tagManagerArgs = {
  gtmId: 'GTM-PVJLXFG',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
