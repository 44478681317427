import { t } from 'i18next';
import validateEmail from 'utils/validateEmail';
import validatePassword from 'utils/validatePassword';
import { State } from './useRegister';

const validate = (values: State) => {
  let errors = {};

  if (!values?.email) {
    errors = { email: t('login:cannot_be_empty') };
  } else if (!validateEmail(values.email)) {
    errors = { email: t('login:invalid_email') };
  }

  if (!values?.name) {
    errors = { ...errors, name: t('login:cannot_be_empty') };
  }

  if (!values?.surname) {
    errors = { ...errors, surname: t('login:cannot_be_empty') };
  }

  if (!values?.repeatPassword) {
    errors = { ...errors, repeatPassword: t('login:cannot_be_empty') };
  }

  if (values?.password !== values?.repeatPassword) {
    errors = { ...errors, repeatPassword: t('login:not_matching_passwords') };
  }

  if (!values?.clauseAccepted) {
    errors = { ...errors, clauseAccepted: t('login:must_be_accepted') };
  }

  errors = { ...errors, ...validatePassword(values?.password) };

  return errors;
};

export default validate;
