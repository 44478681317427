import { useCallback, useState } from 'react';
import ApplicationFile from 'views/Applications/ApplicationFile';
import useList from 'hooks/useList';

const useApplication = (): UseApplication => {
  const [text, setText] = useState('');
  const [files, { push, remove }] = useList<ApplicationFile>();

  const handleDrop = useCallback(
    (files: File[]) => {
      push(files.map(data => new ApplicationFile({ data })));
    },
    [push]
  );

  const handleRemove = useCallback(
    (file: ApplicationFile) => {
      remove(file.id);
    },
    [remove]
  );

  const handleChangeText = useCallback(
    e => {
      setText(e.target.value);
    },
    [setText]
  );

  return { text, files, handleRemove, handleDrop, handleChangeText };
};

type UseApplication = {
  text: string;
  files: ApplicationFile[];
  handleRemove: (file: ApplicationFile) => void;
  handleDrop: (files: File[]) => void;
  handleChangeText: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export default useApplication;
