import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import dayjs from 'dayjs';

import { fetchCaseList, setNavbarActions, setviewTitle } from 'actions';
import { usePagination } from 'components/Pagination';

import TableRow from 'components/Table/items/TableRow';
import TableCell from 'components/Table/items/TableCell';
import TableLayout from 'components/TableLayout';
import Table from 'components/Table';
import Chip from 'components/Chip';
import EmptyState from 'components/EmptyState';
import FilterBar from './items/FilterBar';

import './applications.scss';
import RouteIndex from 'RouteIndex';

const Applications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const caseList = useSelector<StoreState, any>(state => state?.interface?.caseListData?.cases);
  const itemCount = useSelector<StoreState, any>(state => state?.interface?.caseListData?.count);

  const [
    { currentPage, limit, pageCount, offset },
    { lastPage, nextPage, previousPage, firstPage, changeLimit, setPage },
  ] = usePagination({
    itemCount,
  });

  const [searchValue, setSerachValue] = useState<string>('');
  const [search] = useDebounce<string>(searchValue, 800);
  const [date, setDate] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    dispatch(setviewTitle(t('navbar:view_title_applications')));
    dispatch(setNavbarActions({}));
  }, [dispatch, t]);

  useEffect(() => {
    const createdAt = { gt: date && dayjs(date)?.toISOString() };
    dispatch(
      fetchCaseList({
        params: {
          search,
          filter: {
            limit,
            offset,
            order: 'createdAt DESC',
            where: {
              ...(status && { phase: status.toUpperCase() }),
              ...(date && { createdAt: createdAt }),
            },
          },
        },
      })
    );
  }, [dispatch, search, date, status, limit, offset]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'search':
        setSerachValue(value);
        break;
      case 'date':
        setDate(value);
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    navigate(RouteIndex.applicationCreation);
  };

  const handleSelect = (selectedValue: string) => {
    setStatus(selectedValue);
  };

  const goToApplicationDetails = (reference: string) => () => {
    const id = reference.split('/').join('-');
    navigate(RouteIndex.applicationDetails.get({ id }));
  };

  const collumns = ['Nr zapytania', 'Data zapytania', 'Treść', 'Status', ''];

  return (
    <TableLayout
      className="applications"
      currentPage={currentPage}
      limit={limit}
      pageCount={pageCount}
      itemCount={itemCount}
      onClickFirst={firstPage}
      onClickPrevious={previousPage}
      onClickNext={nextPage}
      onClickLast={lastPage}
      onChangeLimit={changeLimit}
      onChangePage={setPage}
      beforeTable={
        <FilterBar
          handleChange={handleChange}
          handleClick={handleClick}
          handleSelect={handleSelect}
          searchValue={searchValue}
          date={date}
          status={status}
        />
      }
    >
      <Table collumns={collumns}>
        {caseList?.map(({ reference, createdAt, description, phase }: any) => {
          const createdDate = dayjs(createdAt).format('DD-MM-YYYY HH:mm');

          return (
            <TableRow key={reference} onClick={goToApplicationDetails(reference)}>
              <TableCell>
                <Chip value={reference} />
              </TableCell>
              <TableCell value={createdDate} />
              <TableCell value={description} />
              <TableCell>
                <Chip status={phase} value={t(`common:status_label_${phase}`)} />
              </TableCell>
              <TableCell>
                <button className="applications__details-btn" onClick={goToApplicationDetails(reference)}>
                  {t('common:details')}
                </button>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
      {caseList?.length === 0 && <EmptyState text={t('applications:no_applications')} />}
    </TableLayout>
  );
};

export default Applications;
