import Endpoint from './Endpoint';

export default {
  register: new Endpoint<RegisterBody>({
    path: '/clients/register',
    method: 'POST',
  }),
  login: new Endpoint<LoginBody>({
    path: '/clients/login',
    method: 'POST',
  }),
  activate: new Endpoint<ActivateBody>({
    path: '/clients/register/activate',
    method: 'POST',
  }),
  cases: new Endpoint<CaseListBody>({
    path: '/client/cases/search',
    method: 'GET',
  }),
  profile: new Endpoint({
    path: '/client/profile',
    method: 'GET',
  }),
  addCase: new Endpoint<AddCase>({
    path: '/client/case',
    method: 'POST',
  }),
  caseDetails: new Endpoint<CaseDetailsBody>({
    path: '/client/case/{reference}',
    method: 'GET',
  }),
  getFile: new Endpoint<GetFileBody>({
    path: '/client/files/{fileId}',
    method: 'GET',
  }),
  requestResetPassword: new Endpoint<RequestResetPasswordBody>({
    path: '/clients/password/reset',
    method: 'POST',
  }),
  resetPassword: new Endpoint<ResetPasswordBody>({
    path: '/clients/password/reset/change',
    method: 'POST',
  }),
  changePassword: new Endpoint<ChangePasswordBody>({
    path: '/clients/password/change',
    method: 'POST',
  }),
  getPaymentDetails: new Endpoint<PaymentDetailsBody>({
    path: '/imoje/payments/details',
    method: 'GET',
  }),
};

type CaseFilter = {
  offset?: number;
  limit?: number;
  skip?: number;
  order?: string;
  where?: {
    phase?: string;
    createdAt?: {
      gt?: string;
    };
  };
};

export type RegisterBody = {
  name: string;
  surname: string;
  email: string;
  password: string;
};
export type LoginBody = {
  email: string;
  password: string;
};
export type ActivateBody = {
  hash: string;
  email: string;
};
export type CaseListBody = {
  params: {
    search: string;
    filter: CaseFilter;
  };
};
export type PaymentDetailsBody = {
  params: {
    orderId: string;
  };
};
export type AddCase = {
  formData: FormData;
};
export type CaseDetailsBody = {
  reference: any;
};
export type GetFileBody = {
  fileId: any;
  responseType: string;
};
export type RequestResetPasswordBody = {
  email: string;
};
export type ResetPasswordBody = {
  hash: string;
  newPassword: string;
  email: string;
};

export type ChangePasswordBody = {
  password: string;
  newPassword: string;
};
