import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import api, {
  RegisterBody,
  ActivateBody,
  LoginBody,
  CaseListBody,
  CaseDetailsBody,
  GetFileBody,
  RequestResetPasswordBody,
  ResetPasswordBody,
  ChangePasswordBody,
  PaymentDetailsBody,
} from 'api/api';
import { NavbarActionsTypes } from 'components/Navbar/NavActions/NavActions';
import { SnackbarKey } from 'notistack';
import { Notification } from 'reducers';
import extractErrors from 'utils/extractErrors';

export const SET_NAVBAR_ACTIONS = 'SET_NAVBAR_ACTIONS';
export const SET_VIEW_TITLE = 'SET_VIEW_TITLE';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_LOGIN_IN_PROGRESS = 'SET_LOGIN_IN_PROGRESS';
export const SET_REGISTER_IN_PROGRESS = 'SET_REGISTER_IN_PROGRESS';
export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const FETCH_CASE_LIST = 'FETCH_CASE_LIST';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_CASE_DETAILS = 'FETCH_CASE_DETAILS';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const PAYMENT_DETAILS = 'PAYMENT_DETAILS';

export const handleErrors = (response: any, dispatch: any) => {
  const errors = extractErrors(response);

  errors.forEach(error => {
    dispatch(enqueueSnackbar(new Notification(error, 'error')));
  });
};

export const setNavbarActions = (navbarActions: NavbarActionsTypes) => {
  return {
    type: SET_NAVBAR_ACTIONS,
    payload: { navbarActions },
  };
};

export const setviewTitle = (title: string) => {
  return {
    type: SET_VIEW_TITLE,
    payload: { viewTitle: title },
  };
};

export const setLoggedIn = (value: boolean) => {
  return {
    type: SET_LOGGED_IN,
    payload: value,
  };
};

export const enqueueSnackbar = (notification: Notification) => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
    },
  };
};

export const closeSnackbar = (key: SnackbarKey) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key: SnackbarKey) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const register = (body: RegisterBody, callback: () => void) => {
  return (dispatch: any) => {
    dispatch({ type: SET_REGISTER_IN_PROGRESS, payload: true });

    api.register
      .call({ body })
      .then(response => {
        if (response.data.success) {
          callback();
        } else {
          handleErrors(response, dispatch);
        }
      })
      .finally(() => {
        dispatch({ type: SET_REGISTER_IN_PROGRESS, payload: false });
      });
  };
};

export const activate = (body: ActivateBody, callback: () => void, error?: () => void) => {
  return (dispatch: any) => {
    api.activate.call({ body }).then(response => {
      if (response.data.success) {
        callback();
      } else {
        handleErrors(response, dispatch);
        if (error) error();
      }
    });
  };
};

export const login = (body: LoginBody, callback?: () => void, error?: (e: string) => void) => {
  return (dispatch: any) => {
    dispatch({ type: SET_LOGIN_IN_PROGRESS, payload: true });

    api.login
      .call({ body })
      .then(({ data }) => {
        if (data.success) {
          const accessToken = data?.data?.accessToken;
          const refreshToken = data?.data?.refreshToken;

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          dispatch({ type: LOGIN });

          if (callback) {
            callback();
          }
        } else {
          if (error) {
            error(data);
          }
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOGIN_IN_PROGRESS, payload: false });
      });
  };
};

export const fetchCaseList = (body: CaseListBody, callback?: () => void) => {
  return (dispatch: any) => {
    api.cases.call({ body }).then(response => {
      if (response.data.success) {
        dispatch({ type: FETCH_CASE_LIST, payload: response.data.data });
        callback && callback();
      }
    });
  };
};

export const fetchPaymentDetails = (body: PaymentDetailsBody, callback?: (pd: any) => void) => {
  return (dispatch: any) => {
    api.getPaymentDetails.call({ body }).then(response => {
      if (response.data.success) {
        dispatch({ type: PAYMENT_DETAILS, payload: response.data.data });
        callback && callback(response.data.data);
      }
    });
  };
};

export const fetchProfile = () => {
  return (dispatch: any) => {
    api.profile.call().then(response => {
      if (response.data.success) {
        dispatch({ type: FETCH_PROFILE, payload: response.data.data });
      }
    });
  };
};

export const fetchCaseDetails = (body: CaseDetailsBody, callback?: () => void) => {
  return (dispatch: any) => {
    const url = `/client/case/${encodeURIComponent(body.reference)}`;

    api.caseDetails.set('path', url);
    api.caseDetails.call({ body }).then(response => {
      if (response.data.success) {
        dispatch({ type: FETCH_CASE_DETAILS, payload: response.data.data });
        callback && callback();
      }
    });
  };
};

export const getFile = (body: GetFileBody, callback?: (response: AxiosResponse) => void) => {
  return () => {
    const url = `/client/files/${encodeURIComponent(body.fileId)}`;

    api.getFile.set('path', url);
    api.getFile.call({ body }).then(response => {
      if (response.status === 200) {
        callback && callback(response);
      }
    });
  };
};

export const requestResetPassword = (body: RequestResetPasswordBody, callback: () => void) => {
  return (dispatch: any) => {
    api.requestResetPassword.call({ body }).then(response => {
      if (response.data.success) {
        callback();
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const resetPassword = (body: ResetPasswordBody, callback: () => void) => {
  return (dispatch: any) => {
    api.resetPassword.call({ body }).then(response => {
      if (response.data.success) {
        dispatch(enqueueSnackbar(new Notification('Hasło zostało zmienione', 'success')));
        callback();
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const changePassword = (body: ChangePasswordBody, callback: () => void) => {
  return (dispatch: any) => {
    api.changePassword.call({ body }).then(response => {
      if (response.data.success) {
        dispatch(enqueueSnackbar(new Notification(t('notifications:password_changed'), 'success')));
        callback();
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};
