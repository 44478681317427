import { nanoid } from 'nanoid';
import ListElement from 'hooks/useList/ListElement';

const units = ['B', 'KB', 'MB', 'GB'];

export default class ApplicationFile implements ListElement {
  public readonly id: string;
  public readonly data: File;

  constructor({ data }: Arguments) {
    this.id = nanoid();
    this.data = data;
  }

  private calcSize = (size: number, depth = 0): [number, number] => {
    if (size / 1024 < 0.01) {
      return [size, depth];
    }
    return this.calcSize(size / 1024, depth + 1);
  };

  getSize = (): string => {
    const [size, unitIdx] = this.calcSize(this.data.size);
    const unit = units[unitIdx];

    return `${size.toFixed(2)}${unit}`;
  };
}

type Arguments = {
  data: File;
};
