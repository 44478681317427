import React, { memo, useEffect, useMemo } from 'react';
import { activate } from 'actions';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteIndex from 'RouteIndex';

const Activate = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const navigate = useNavigate();

  useEffect(() => {
    const hash = params.get('hash');
    const email = params.get('email');

    if (!hash || !email) {
      return;
    }

    dispatch(
      activate(
        { hash, email },
        () => {
          navigate(RouteIndex.login);
        },
        () => {
          navigate(RouteIndex.login);
        }
      )
    );
  }, [params, dispatch, navigate]);

  return null;
};

export default memo(Activate);
