import { ChangeEvent, useCallback, useState } from 'react';
import { useFormik, FormikErrors, FormikTouched } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { register as registerAction } from 'actions';
import { StoreState } from 'store';
import validate from './validate';

const useRegister = (): UseRegister => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<Step>('form');
  const loading = useSelector<StoreState, boolean>(state => state.session.registerLoading);
  const formik = useFormik<State>({
    initialValues: {
      email: '',
      name: '',
      surname: '',
      password: '',
      repeatPassword: '',
      clauseAccepted: false,
    },
    validate,
    onSubmit: values => {
      const { email, name, password, surname } = values;

      dispatch(
        registerAction({ email, name, password, surname }, () => {
          setStep('result');
        })
      );
    },
  });

  const toggleClause = useCallback(() => {
    formik.setValues(state => ({ ...state, clauseAccepted: !state.clauseAccepted }));
  }, [formik]);

  return [
    {
      ...formik.values,
      loading,
      errors: formik.errors,
      touched: formik.touched,
      step,
    },
    {
      change: formik.handleChange,
      register: formik.handleSubmit,
      toggleClause,
      onBlur: formik.handleBlur,
    },
  ];
};

export type State = {
  email: string;
  name: string;
  surname: string;
  password: string;
  repeatPassword: string;
  clauseAccepted: boolean;
};

type Step = 'form' | 'result';

type UseRegister = [
  State & {
    clauseAccepted: boolean;
    loading: boolean;
    errors: FormikErrors<State>;
    touched: FormikTouched<State>;
    step: Step;
  },
  {
    change: (e: ChangeEvent<HTMLInputElement>) => void;
    register: () => void;
    toggleClause: () => void;
    onBlur: (e: React.FocusEvent<any, Element>) => void;
  }
];

export default useRegister;
