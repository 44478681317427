import React, { FC, memo, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Dropzone from 'components/Dropzone';
import Button from 'components/Button';
import Flex from 'components/Flex';
import UploadIcon from 'assets/images/icon-upload.svg';
import './file-upload.scss';

const FileUpload: FC<Props> = ({ maxSize, onDrop, className = '' }) => {
  const { t } = useTranslation('common');

  const classes = clsx('file-upload', className);

  const handleDrop = useCallback(
    (files: File[]) => {
      onDrop(files);
    },
    [onDrop]
  );

  return (
    <Dropzone className={classes} maxSize={maxSize} onDrop={handleDrop}>
      {({ open }) => (
        <Flex align="center">
          <img src={UploadIcon} className="file-upload__icon" />
          <div className="file-upload__content">
            <h2 className="content__heading">
              {t('dropzone_label_1')}
              <Button width="auto" variant="text" className="content__button" onClick={open}>
                {t('dropzone_label_2')}
              </Button>
            </h2>
            <p className="content__text">{t('dropzone_content_text')}</p>
          </div>
        </Flex>
      )}
    </Dropzone>
  );
};

type Props = {
  maxSize: number;
  className?: string;
  onDrop: (files: File[]) => void;
};

export default memo(FileUpload);
