import React, { FC, memo } from 'react';
import EmptyStateIcon from 'assets/images/empty-state.png';
import './empty-state.scss';

const EmptyState: FC<Props> = ({ text }) => {
  return (
    <div className="empty-state">
      <div className="empty-state__container">
        <img className="container__image" src={EmptyStateIcon} />
        {text && <span className="container__text">{text}</span>}
      </div>
    </div>
  );
};

type Props = {
  text?: string;
};

export default memo(EmptyState);
