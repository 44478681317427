import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonBase from 'components/ButtonBase';
import useSession from 'hooks/useSession';
import NavLink from './items/NavLink';
import Navsettings from './items/NavSettings';

import { ReactComponent as Logo } from 'assets/images/logo-white.svg';
import { ReactComponent as Applications } from 'assets/images/applications_icon.svg';

import { ReactComponent as Exit } from 'assets/images/exit_icon.svg';

import './sidebar.scss';

const Sidebar = () => {
  const { t } = useTranslation();
  const { logout } = useSession();

  return (
    <aside className="sidebar">
      <div className="sidebar__content">
        <Logo className="sidebar__logo" />
        <menu className="sidebar__menu">
          <NavLink label={t('sidebar:label_applications')} to="/" icon={<Applications className="sidebar__icon" />} />

          <Navsettings />
        </menu>
      </div>

      <ButtonBase className="sidebar__logout" onClick={logout}>
        <Exit className="sidebar__icon" />
        <span className="link__label">{t('sidebar:label_logout')}</span>
      </ButtonBase>
    </aside>
  );
};

export default Sidebar;
