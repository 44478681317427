import React, { FC } from 'react';

type Props = {
  collumns: string[];
};

const TableHead: FC<Props> = ({ collumns }) => {
  return (
    <thead className="table__head">
      <tr>
        {collumns.map((col: string) => (
          <th key={col}>{col}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
