import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setNavbarActions, setviewTitle } from 'actions';
import Flex from 'components/Flex';
import useProfile from './useProfile';
import Entry from './Entry';

import { ReactComponent as DownloadIcon } from 'assets/images/icon-download.svg';
import './profile.scss';
import { HOST } from 'api/client';

const Profile = () => {
  const { t } = useTranslation('profile');
  const { profile } = useProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    let name = '';

    if (profile) {
      name = `${profile.name} ${profile.surname}`;
    }

    dispatch(setviewTitle(t('navbar:view_title_profile', { userName: name })));
    dispatch(setNavbarActions({}));
  }, [t, profile, dispatch]);

  if (!profile) {
    return null;
  }

  const { companyName, phone, email, activePackage } = profile;
  const packageName = activePackage && activePackage.name ? activePackage.name : `${t('package')}`;
  const packageActiveTo = activePackage && String(activePackage.activeTo).slice(0, 10);

  const instructionsUrl = `${HOST}/files/open/Instrukcja_Abonamentu_Lex_Secure.pdf`;

  return (
    <Flex className="profile">
      <div className="profile__leftside">
        {companyName && <Entry label={t('label_company')} value={companyName} />}
        {phone && <Entry label={t('label_phone')} value={phone} />}
        {email && <Entry label={t('label_email')} value={email} />}
        {activePackage ? (
          packageActiveTo === 'null' || packageActiveTo === null ? (
            <Entry label={t('label_plan')} value={`${packageName}, ${t('withoutValidUntil')}`} />
          ) : (
            <Entry label={t('label_plan')} value={`${packageName}, ${t('validUntil')} ${packageActiveTo}`} />
          )
        ) : (
          <Entry label={t('label_plan')} value={t('noActivePackage')} />
        )}
        <p className="profile__info">{t('profile:info')}</p>

        <div className="contact">
          <Flex justify="space-between">
            24H {t('label_hotline_legal')}:<a href="tel:+ 48 501 538 539">+ 48 501 538 539</a>
          </Flex>

          <Flex justify="space-between">
            {t('label_write_to_us')}:<a href="mailto:prawnik@opiekaprawna.pl">prawnik@opiekaprawna.pl</a>
          </Flex>
        </div>
      </div>
      <div className="profile__rightside">
        <Entry className="rightside__entry" label={t('label_plans')} value="" />
        <DownloadIcon className="file-download__icon" />
        <a href={instructionsUrl} target="_blank" rel="noopener noreferrer">
          {t('information_pkg')}
        </a>
      </div>
    </Flex>
  );
};

export default Profile;
