import React from 'react';
import { useTranslation } from 'react-i18next';

import ChangePasswordModal from 'views/ChangePasswordModal';
import Accordion from 'components/Accordion';
import NavLink from 'components/Sidebar/items/NavLink';
import useToggle from 'hooks/useToggle';

import { ReactComponent as Settings } from 'assets/images/settings_icon.svg';

const NavSettings = () => {
  const { t } = useTranslation();
  const [open, { set }] = useToggle();

  return (
    <>
      <ChangePasswordModal open={open} close={() => set(false)} />
      <Accordion
        buttonContent={
          <>
            <Settings className="sidebar__icon" />
            <span className="link__label">{t('sidebar:label_settings')}</span>
          </>
        }
      >
        <NavLink to="/profile" label={t('sidebar:label_profile')} />
        <button className="sidebar__link" type="button" onClick={() => set(true)}>
          {t('sidebar:label_change_password')}
        </button>
      </Accordion>
    </>
  );
};

export default NavSettings;
