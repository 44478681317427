import client from './client';

export default class Endpoint<Body = Record<string, never>> {
  public readonly method: Methods;
  public path: string;

  constructor({ path, method }: Arguments) {
    this.path = path;
    this.method = method;
  }

  set = (name: SetName, value: string) => {
    switch (name) {
      case 'path':
        this.path = value;
        break;
      // case 'contentType':
      //     client.set(value);
      //     break;
      default:
        break;
    }
  };

  get = (options?: GetOptions<Body>): string => {
    //
    return `${this.path}`;
  };

  call = (options?: GetOptions<Body>): Promise<any> => {
    const { body } = options || {};
    // return client.use(this.method)(this.get(options), options);
    return client.use(this.method)(this.get(options), body as any);
  };
}

export type Methods = 'GET' | 'POST' | 'DELETE' | 'PUT';
type SetName = 'contentType' | 'path';

type Arguments = {
  path: string;
  method: Methods;
};

type GetOptions<T> = {
  params?: Record<string, string>;
  query?: Record<string, string>;
  body?: T;
};
