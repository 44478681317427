import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';

import { getFile } from 'actions';
import downloadFile from 'utils/downloadFile';
import Flex from 'components/Flex';

import { ReactComponent as DownloadIcon } from 'assets/images/icon-download.svg';
import './file-download.scss';

const FileDownload: FC<Props> = ({ id, size, title }) => {
  const dispatch = useDispatch();

  const fetchFile = () => {
    dispatch(
      getFile({ fileId: id, responseType: 'arraybuffer' }, response => {
        downloadFile(response, title);
      })
    );
  };

  return (
    <Flex align="center" className="file-download">
      <DownloadIcon className="file-download__icon" />
      <button onClick={fetchFile} type="button" className="file-download__button">
        <span className="file-download__file-name">{title}</span>
        {size && <span className="file-download__file-size"> ({size})</span>}
      </button>
    </Flex>
  );
};

type Props = {
  id?: number;
  size?: string;
  title: string;
};

export default memo(FileDownload);
