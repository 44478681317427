import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo.png';

import './payment.scss';
import { useDispatch } from 'react-redux';
import { fetchPaymentDetails } from 'actions';
import WideStartLayout from 'components/StartLayout/WideStartLayout';

const Payment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const orderId = new URLSearchParams(search).get('orderId');

  const [paymentDetails, setPaymentDetails] = useState<undefined | any>();

  useEffect(() => {
    if (orderId) {
      dispatch(
        fetchPaymentDetails({ params: { orderId: orderId } }, pd => {
          if (pd) {
            setPaymentDetails(pd);
          } else {
            navigate(`/login`);
          }
        })
      );
    }
  }, [orderId]);

  useEffect(() => {
    if (!paymentDetails) return;
    const script = document.createElement('script');

    script.src =
      process.env.REACT_APP_API_URL === 'https://panel-api.lexsecure.com'
        ? 'https://paywall.imoje.pl/js/widget.min.js'
        : 'https://sandbox.paywall.imoje.pl/js/widget.min.js';
    script.async = true;
    script.setAttribute('id', 'imoje-widget__script');
    script.setAttribute('data-merchant-id', paymentDetails.merchantId);
    script.setAttribute('data-service-id', paymentDetails.serviceId);
    script.setAttribute('data-amount', paymentDetails.amount);
    script.setAttribute('data-currency', paymentDetails.currency);
    script.setAttribute('data-order-id', paymentDetails.orderId);
    script.setAttribute('data-customer-id', paymentDetails.customerId);
    script.setAttribute('data-customer-first-name', paymentDetails.customerFirstName);
    script.setAttribute('data-customer-last-name', paymentDetails.customerLastName);
    script.setAttribute('data-customer-email', paymentDetails.customerEmail);
    script.setAttribute('data-url-return', paymentDetails.urlReturn);
    script.setAttribute('data-valid-to', paymentDetails.validTo);
    script.setAttribute('data-widget-type', paymentDetails.widgetType);
    script.setAttribute('data-signature', `${paymentDetails.signature};sha256`);
    script.setAttribute('data-inline', 'true');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [paymentDetails]);

  return (
    <div className="Payment">
      <WideStartLayout
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '50px', marginBottom: '50px' }}>
            <img src={Logo} alt="" className="welcome-topbar__logo" />
            {paymentDetails !== undefined ? (
              <>
                <span className="text3">
                  Imię: <b>{paymentDetails.customerFirstName}</b>
                </span>
                <span className="text3">
                  Nazwisko: <b>{paymentDetails.customerLastName}</b>
                </span>
                <span className="text3">
                  E-mail: <b>{paymentDetails.customerEmail}</b>
                </span>
                <span style={{ flexGrow: 1 }}></span>
                <span className="text3">
                  Pakiet: <b>{paymentDetails.packageName}</b>
                </span>
                <span className="text3">
                  Kwota płatności: <b>{(Number(paymentDetails.amount) / 100).toFixed(2)} PLN</b>
                </span>
              </>
            ) : null}
          </div>
          {orderId !== undefined ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '40px',
                flexDirection: 'column',
                paddingRight: '50px',
                gap: '40px',
              }}
            >
              <div id="imoje-widget__wrapper" style={{ alignSelf: 'center' }}></div>
            </div>
          ) : null}
        </div>
      </WideStartLayout>
    </div>
  );
};

export default memo(Payment);
