import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { handleErrors, setNavbarActions, setviewTitle } from 'actions';
import FileUpload from 'components/FileUpload';
import FileList from 'components/FileList';
import Button from 'components/Button';
import Flex from 'components/Flex';
import useApplication from './useApplication';
import { HOST } from 'api/client';

import './new-application.scss';
import RouteIndex from 'RouteIndex';

const MAX_FILE_SIZE = 30 * 1024 * 1024;

const NewApplication = () => {
  const {
    text,
    // files, handleDrop, handleRemove,
    handleChangeText,
  } = useApplication();
  const { t } = useTranslation('applications');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataFiles, setDataFiles] = useState<File[]>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const drop = (files: File[]) => {
    setDataFiles([...dataFiles, ...files]);
  };

  const remove = (file: File) => () => {
    const filteredFiles = dataFiles.filter(filteredFile => filteredFile.lastModified !== file.lastModified);
    setDataFiles(filteredFiles);
  };

  useEffect(() => {
    dispatch(setviewTitle(t('new_application')));
  }, [dispatch, t]);

  const sendCase = useCallback(() => {
    if (!text) return;

    const formData = new FormData();
    dataFiles.forEach(file => formData.append('file', file));
    formData.append('description', text);
    setDisabledButton(true);
    axios({
      method: 'post',
      url: `${HOST}/client/case`,
      data: formData,
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then(response => {
        if (response.data.success) {
          setDisabledButton(false);
          navigate('/');
        } else {
          handleErrors(response, dispatch);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [dataFiles, dispatch, navigate, text]);

  useEffect(() => {
    dispatch(
      setNavbarActions({
        fn: sendCase,
        label: 'newCase',
        disabled: disabledButton,
      })
    );
  }, [text, dataFiles, dispatch, sendCase, disabledButton]);

  const navigateTo = (route: string) => () => {
    navigate(route);
  };

  return (
    <div className="new-application">
      <Flex justify="space-between" className="new-application__data">
        <div className="data__leftside">
          <p className="new-application__label">{t('section_label_content')}</p>
          <textarea
            className="leftside__textarea"
            value={text}
            placeholder={t('add_application_placeholder')}
            onChange={handleChangeText}
          ></textarea>
        </div>
        <div className="data__rightside">
          <p className="new-application__label">{t('section_label_dropzone')}</p>
          <FileUpload maxSize={MAX_FILE_SIZE} onDrop={drop} className="rightside__file-upload" />
          <FileList files={dataFiles} onClickRemove={remove} />
        </div>
      </Flex>

      <Flex className="new-application__actions">
        <Button
          width="auto"
          height="compact"
          className="actions__button"
          variant="ghost"
          onClick={navigateTo(RouteIndex.home)}
        >
          {t('common:cancel')}
        </Button>

        <Button disabled={disabledButton} width="auto" height="compact" className="actions__button" onClick={sendCase}>
          {t('common:send_application')}
        </Button>
      </Flex>
    </div>
  );
};

export default NewApplication;
