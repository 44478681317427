import React, { memo, FC, ReactElement, ReactNode } from 'react';
import Progress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import ButtonBase from 'components/ButtonBase';
import Flex from 'components/Flex';
import './button.scss';

const Button: FC<Props> = ({
  children,
  className = '',
  variant = 'contained',
  width = 'auto',
  height = 'default',
  disabled = false,
  loading = false,
  startIcon,
  endIcon,
  onClick,
}) => {
  const classes = clsx('button', className, `width-${width}`, `variant-${variant}`, `height-${height}`, {
    disabled,
  });

  return (
    <ButtonBase className={classes} disabled={disabled} onClick={onClick}>
      <Flex align="center" justify="center">
        {loading ? (
          <Progress size={20} color="inherit" />
        ) : (
          <>
            {startIcon && <div className="button__start-icon">{startIcon}</div>}
            {children}
            {endIcon && <div className="button__end-icon">{endIcon}</div>}
          </>
        )}
      </Flex>
    </ButtonBase>
  );
};

type Props = {
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  width: 'auto' | 'fill';
  variant?: 'contained' | 'ghost' | 'text';
  height?: 'default' | 'compact';
  type?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export default memo(Button);
