import React, { FC, memo, ReactNode } from 'react';
import ButtonBase from 'components/ButtonBase';
import Flex from 'components/Flex';
import ActionIcon from 'assets/images/icon-action-chevron.svg';
import './type-button.scss';

const TypeButton: FC<Props> = ({ children, icon, onClick }) => {
  return (
    <ButtonBase className="type-button" onClick={onClick}>
      <Flex align="center" justify="space-between">
        <Flex align="center">
          {icon && <img src={icon} alt="" className="type-button__icon" />}
          {children}
        </Flex>
        <div className="type-button__action-icon">
          <img src={ActionIcon} />
        </div>
      </Flex>
    </ButtonBase>
  );
};

type Props = {
  onClick: () => void;
  children: ReactNode;
  icon: string;
};

export default memo(TypeButton);
