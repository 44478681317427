import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

import './panel.scss';

type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  label: string;
};

const Panel: FC<Props> = ({ children, className, label = '' }) => {
  const classes = clsx('panel', className);
  return (
    <div className={classes}>
      <h4 className="panel__label">{label}</h4>
      <div className="panel__content">{children}</div>
    </div>
  );
};

export default Panel;
