import React, { useEffect } from 'react';
import { StoreState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { fetchCaseDetails, setNavbarActions, setviewTitle } from 'actions';
import calcSize from 'utils/calcSize';

import Flex from 'components/Flex';
import Chip from 'components/Chip';
import Panel from 'components/Panel';
import FileDownload from 'components/FileDownload';

import './application-details.scss';

const ApplicationDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const details = useSelector<StoreState, any>(state => state?.interface?.case);
  const createdDate = dayjs(details?.case?.createdAt).format('DD-MM-YYYY HH:mm');

  useEffect(() => {
    const reference = id?.split('-').join('/') || '';
    dispatch(fetchCaseDetails({ reference }));
    dispatch(setviewTitle(t('navbar:view_title_applicationDetails')));
    dispatch(setNavbarActions({ label: 'goBack' }));
  }, []);

  return (
    <div className="application-details">
      <div className="application-details__headline">
        <span className="headline__label weight400">{t('applicationDetails:application_nr')}: </span>
        <span className="headline__data weight600">{details?.case?.reference}</span>
      </div>

      <Flex className="application-details__status" justify="space-between">
        <Panel label={`${t('applicationDetails:application_status')}: `}>
          <Chip status={details?.case?.phase} value={t(`common:status_label_${details?.case?.phase}`)} />
        </Panel>
        <Panel label={`${t('applicationDetails:application_date')}:`}>
          <p className="application__value">{createdDate}</p>
        </Panel>
      </Flex>

      {details?.case?.description && (
        <Panel className="application-details__message" label={`${t('applicationDetails:application_content')}: `}>
          <p className="application__value">{details?.case?.description}</p>
        </Panel>
      )}

      {details?.files && (
        <Panel className="application-details__files" label={`${t('applicationDetails:application_files_question')}: `}>
          {details?.files.map((file: any) => (
            <FileDownload
              key={file?.id}
              id={file?.id}
              title={file?.originalName}
              size={calcSize(Number(file?.fileSize))}
            />
          ))}
        </Panel>
      )}
      <h3 className="application__subtitle"></h3>
    </div>
  );
};

export default ApplicationDetails;
