import React, { FC, memo } from 'react';
import clsx from 'clsx';
import Flex from 'components/Flex';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import Select from './RecordAmountSelect';

import ChevronLeft from 'assets/images/chevron_left.svg';
import DoubleChevronLeft from 'assets/images/chevron_left_double.svg';
import ChevronRight from 'assets/images/chevron_right.svg';
import DoubleChevronRight from 'assets/images/chevron_right_double.svg';
import './pagination.scss';

const Pagination: FC<Props> = ({
  itemCount,
  currentPage,
  limit,
  pageCount,
  className = '',
  onChangeLimit,
  onClickLast,
  onClickNext,
  onClickPrevious,
  onClickFirst,
  onChangePage,
}) => {
  const { t } = useTranslation('common');
  const classes = clsx('pagination', className);

  return (
    <Flex align="center" className={classes}>
      <Flex align="center" className="pagination__limit">
        <span className="limit__total">{t('pagination_found_cases', { count: itemCount })}</span>
      </Flex>
      <div className="pagination__pages">
        <Flex align="center" className="pagination__actions">
          <IconButton className="pagination__page-nav" size="small" onClick={onClickFirst}>
            <img src={DoubleChevronLeft} />
          </IconButton>
          <IconButton className="pagination__page-nav" size="small" onClick={onClickPrevious}>
            <img src={ChevronLeft} />
          </IconButton>

          <div className="pagination__page-count">
            <span>{t('pagination_current_page', { currentPage, pageCount })}</span>
          </div>

          <IconButton className="pagination__page-nav" size="small" onClick={onClickNext}>
            <img src={ChevronRight} />
          </IconButton>
          <IconButton className="pagination__page-nav" size="small" onClick={onClickLast}>
            <img src={DoubleChevronRight} />
          </IconButton>
        </Flex>
      </div>

      <Flex align="center" className="pagination__go-to-page">
        <p>{t('pagination_go_to_page')}</p>
        <input type="number" name="page" max={pageCount} onChange={onChangePage} />
      </Flex>

      <Select options={[10, 20, 30, 40, 50]} value={limit} onSelect={onChangeLimit} />
    </Flex>
  );
};

type Props = {
  itemCount: number;
  currentPage: number;
  pageCount: number;
  limit: number;
  className?: string;
  onChangeLimit: (count: number) => void;
  onClickLast: () => void;
  onClickNext: () => void;
  onClickPrevious: () => void;
  onClickFirst: () => void;
  onChangePage: (event: any) => void;
};

export default memo(Pagination);
