import { useCallback, useState } from 'react';
import ListElement from './ListElement';

const useList = <T extends ListElement = ListElement>(): UseList<T> => {
  const [list, setList] = useState<T[]>([]);

  const remove = useCallback((id: string) => {
    setList(prev => prev.filter(item => item.id !== id));
  }, []);

  const push = useCallback((item: T | T[]) => {
    if (Array.isArray(item)) {
      setList(prev => [...prev, ...item]);
    } else {
      setList(prev => [...prev, item]);
    }
  }, []);

  return [list, { push, remove }];
};

type UseList<T> = [
  T[],
  {
    push: (item: T | T[]) => void;
    remove: (id: string) => void;
  }
];

export default useList;
