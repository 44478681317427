import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/Input';
import { ReactComponent as SearchIcon } from 'assets/images/icon-search.svg';
import { ReactComponent as Chevron } from 'assets/images/chevron.svg';

import './search.scss';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

const Search: FC<Props> = ({ onChange, value = '' }) => {
  const { t } = useTranslation('applications');

  const [searchValue, setsearchValue] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setsearchValue(e.target.value);
    onChange(e);
  };

  return (
    <div className="search">
      <Input
        onChange={handleChange}
        value={searchValue}
        placeholder={t('case_search_placeholder')}
        name="search"
        endIcon={<Chevron className="search__dropdown-icon" />}
        startIcon={<SearchIcon />}
      />
    </div>
  );
};

export default Search;
