import { t } from 'i18next';
import validateEmail from 'utils/validateEmail';

const validate = (values: Record<string, string>) => {
  let errors = {};

  if (!values.password) {
    errors = { password: t('login:cannot_be_empty') };
  }

  if (!values.email) {
    errors = { ...errors, email: t('login:cannot_be_empty') };
  } else if (!validateEmail(values.email.trim())) {
    errors = { ...errors, email: t('login:invalid_email') };
  }

  return errors;
};

export default validate;
