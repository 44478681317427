import React, { memo } from 'react';
import AuthNotification from 'components/AuthNotification';
import StartLayout from 'components/StartLayout';
import './activate-error.scss';

const ActivateError = () => {
  return (
    <div className="activate-error">
      <StartLayout contentClass="activate-error__content" contentWrapperClass="activate-error__wrapper">
        <AuthNotification
          title="Coś poszło nie tak!"
          text="Pracujemy już na tym, jeśli jednak problem będzie się powtarzał skontaktuj się z nami."
          buttonLabel="Wyślij ponownie link aktywacyjny"
          onClick={() => {
            //
          }}
        />
      </StartLayout>
    </div>
  );
};

export default memo(ActivateError);
