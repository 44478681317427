import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import applicationDetailsEn from 'assets/locales/en/application-details.json';
import applicationDetailsPl from 'assets/locales/pl/application-details.json';
import commonEn from './assets/locales/en/common.json';
import commonPl from './assets/locales/pl/common.json';
import loginEn from './assets/locales/en/login.json';
import loginPl from './assets/locales/pl/login.json';
import navbarEn from './assets/locales/en/navbar.json';
import navbarPl from './assets/locales/pl/navbar.json';
import sidebarEn from './assets/locales/en/sidebar.json';
import sidebarPl from './assets/locales/pl/sidebar.json';
import applicationsPl from './assets/locales/pl/applications.json';
import applicationsEn from './assets/locales/en/applications.json';
import profileEn from './assets/locales/en/profile.json';
import profilePl from './assets/locales/pl/profile.json';
import notificationEn from './assets/locales/en/notifications.json';
import notificationPl from './assets/locales/pl/notifications.json';
import welcomeEn from './assets/locales/en/welcome.json';
import welcomePl from './assets/locales/pl/welcome.json';

const resources = {
  pl: {
    applicationDetails: applicationDetailsPl,
    common: commonPl,
    login: loginPl,
    navbar: navbarPl,
    sidebar: sidebarPl,
    applications: applicationsPl,
    profile: profilePl,
    notifications: notificationPl,
    welcome: welcomePl,
  },
  en: {
    applicationDetails: applicationDetailsEn,
    common: commonEn,
    login: loginEn,
    navbar: navbarEn,
    sidebar: sidebarEn,
    applications: applicationsEn,
    profile: profileEn,
    notifications: notificationEn,
    welcome: welcomeEn,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'pl',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
    wait: false,
  },
});
