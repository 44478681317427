import React, { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { SelectOptionsProps } from '../Select.types';

const SelectOptions: FC<SelectOptionsProps> = ({ options, classes, onSelect }) => {
  const { t } = useTranslation('common');

  const handleSelect = (option: string) => () => onSelect(option);

  const optionItemClasses = clsx('select__option-item', classes?.optionItem);
  const optionsClasses = clsx('select__options');

  return (
    <ul className={optionsClasses}>
      {options.map((option: string) => (
        <li key={option} className={optionItemClasses} onClick={handleSelect(option)}>
          {t(`status_${option}`)}
        </li>
      ))}
    </ul>
  );
};

export default SelectOptions;
