import React, { FC, memo } from 'react';
import clsx from 'clsx';
import './entry.scss';

const Entry: FC<Props> = ({ label, value, className = '' }) => {
  const classes = clsx('entry', className);

  return (
    <div className={classes}>
      <h4 className="entry__label">{label}</h4>
      <span className="entry__value">{value}</span>
    </div>
  );
};

type Props = {
  label: string;
  value: string;
  className?: string;
};

export default memo(Entry);
