import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import AuthNotification from 'components/AuthNotification/AuthNotification';
import Reference from 'components/Reference';
import StartLayout from 'components/StartLayout';
import Checkbox from 'components/Checkbox';
import Headline from 'components/Headline';
import Button from 'components/Button';
import Flex from 'components/Flex';
import InputLabel from 'components/InputLabel';
import Input from 'components/Input';

import EyeIcon from 'assets/images/icon-eye.png';
import ClosedEyeIcon from 'assets/images/icon-eye-closed.svg';
import useRegister from './useRegister';
import RouteIndex from 'RouteIndex';
import './register.scss';
import { HOST } from 'api/client';

const Register = () => {
  const regulationsUrl = `${HOST}/files/open/regulamin-lex-secure.pdf`;
  const { t } = useTranslation();
  const [
    { email, name, surname, password, repeatPassword, loading, errors, touched, clauseAccepted, step },
    { change, register, onBlur, toggleClause },
  ] = useRegister();
  const [visible, setVisible] = useState({
    password: false,
    repeatPassword: false,
  });

  const handleChangeVisibility = (name: 'password' | 'repeatPassword') => () => {
    setVisible(prev => ({ ...prev, [name]: !prev[name] }));
  };

  const isPasswordTouched = touched.password || touched.repeatPassword;

  let content;

  if (step === 'result') {
    content = (
      <AuthNotification
        title={t('login:check_inbox_title')}
        text={t('login:check_inbox_text', { email })}
        referenceText={t('login:resend_activation_link_text')}
        referenceLink={t('login:resend_activation_link')}
        referenceHref=" "
        onClickReference={e => {
          e.preventDefault();
          console.log('resend activation link');
        }}
      />
    );
  } else {
    content = (
      <form onSubmit={register}>
        <Flex direction="column">
          <Headline className="register__headline register__row">{t('login:register_headline')}</Headline>

          <span className="register__text">{t('login:register_text')}</span>

          <Flex width="fill" justify="space-between" className="register__split">
            <div className="split__input">
              <InputLabel text={t('login:register_form_label_name')} required />
              <Input
                width="fill"
                placeholder={t('login:register_form_placeholder_name')}
                className="register__input"
                name="name"
                value={name}
                error={(touched.name && errors.name) || ''}
                onChange={change}
                onBlur={onBlur}
              />
            </div>
            <div className="split__input">
              <InputLabel text="Nazwisko" required />
              <Input
                width="fill"
                placeholder={t('login:register_form_placeholder_name')}
                className="register__input"
                name="surname"
                value={surname}
                error={(touched.surname && errors.surname) || ''}
                onChange={change}
                onBlur={onBlur}
              />
            </div>
          </Flex>

          <InputLabel text={t('login:register_form_label_email')} required />
          <Input
            width="fill"
            placeholder={t('login:register_form_placeholder_email')}
            className="register__row register__input"
            name="email"
            value={email}
            error={(touched.email && errors.email) || ''}
            onChange={change}
            onBlur={onBlur}
          />

          <Flex width="fill" justify="space-between" className="register__split">
            <div className="split__input">
              <InputLabel text={t('login:register_form_label_password')} required />
              <Input
                width="fill"
                type={visible.password ? 'text' : 'password'}
                placeholder={t('login:form_label_password')}
                className="register__input"
                endIcon={
                  <IconButton size="small" onClick={handleChangeVisibility('password')}>
                    <img src={visible.password ? ClosedEyeIcon : EyeIcon} alt="" />
                  </IconButton>
                }
                name="password"
                value={password}
                error={(isPasswordTouched && errors.password) || ''}
                onChange={change}
                onBlur={onBlur}
              />
            </div>
            <div className="split__input">
              <InputLabel text={t('login:register_form_label_password_repeat')} required />
              <Input
                width="fill"
                type={visible.repeatPassword ? 'text' : 'password'}
                placeholder={t('login:form_label_password')}
                className="register__input"
                endIcon={
                  <IconButton size="small" onClick={handleChangeVisibility('repeatPassword')}>
                    <img src={visible.repeatPassword ? ClosedEyeIcon : EyeIcon} alt="" />
                  </IconButton>
                }
                name="repeatPassword"
                value={repeatPassword}
                error={(isPasswordTouched && errors.repeatPassword) || ''}
                onChange={change}
                onBlur={onBlur}
              />
            </div>
          </Flex>

          <Flex justify="space-between" className="register__clause">
            <Checkbox checked={clauseAccepted} onChange={toggleClause} />
            <Flex direction="column">
              <p className="clause__text">
                <span className="clause__text">{t('login:register_clause1')}</span>
                <a href={regulationsUrl} target="_blank" rel="noopener noreferrer">
                  {t('login:register_clause2')}
                </a>
                <span className="clause__text">{t('login:register_clause3')}</span>
              </p>
              {touched.clauseAccepted && errors.clauseAccepted && (
                <div className="clause__error">{errors.clauseAccepted}</div>
              )}
            </Flex>
          </Flex>

          <Button width="fill" type="submit" onClick={register} className="register__button" loading={loading}>
            {t('login:register_headline')}
          </Button>
        </Flex>
      </form>
    );
  }

  return (
    <div className="register">
      <StartLayout
        className="register__layout"
        contentClass="register__layout-content"
        top={<Reference text={t('login:register_reference_text')} link={t('login:login')} href={RouteIndex.login} />}
      >
        {content}
      </StartLayout>
    </div>
  );
};

export default memo(Register);
